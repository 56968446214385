<template>
    <website-page class="cms-page-blocks" :class="{ 'preview': preview }">
        <preview-responsive-container>
            <cms-block-builder :blocks="cmsPage.blocks"/>
        </preview-responsive-container>
    </website-page>
</template>

<script>
import CmsBlockBuilder from "@/components/editor/rendering/blocks/cms-block-builder.vue";
import PreviewResponsiveContainer from "@/layout/preview-responsive-container.vue";

// web-editor
export default {
    name: `cms-page-blocks`,
    components: {
        CmsBlockBuilder,
        PreviewResponsiveContainer,
        // EResponsivePreview
    },
    props: {
        preview: { type: Boolean, default: false }
    },
    data() {
        return {
            loading: false
        }
    }
}
</script>

<style lang="scss">
</style>
